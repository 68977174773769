<template>
  <div class="statistics-corners-goals">
    <StatisticsCornersGoalsMobile v-if="isMobile" :data-by-period="dataByPeriod" />
    <StatisticsCornersGoalsDesktop v-else :data-by-period="dataByPeriod" :is-corners="this.type === 'corners'" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'StatisticsCornersGoals',
  components: {
    StatisticsCornersGoalsDesktop: () =>
      import('@/components/Sections/GolTipster-v2/General/StatisticsCornersGoalsDesktop'),
    StatisticsCornersGoalsMobile: () =>
      import('@/components/Sections/GolTipster-v2/General/StatisticsCornersGoalsMobile'),
  },
  props: {
    type: {
      type: String,
      default: 'corners', // 'corners' or 'goals'
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', ['cornersDataByPeriod', 'goalsDataByPeriod']),
    isMobile() {
      return this.displayWidth < 930;
    },
    dataByPeriod() {
      return this.type === 'corners' ? this.cornersDataByPeriod : this.goalsDataByPeriod;
    },
  },
};
</script>

<style scoped lang="scss">
.statistics-corners-goals {
  width: 100%;
}
</style>
